import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion, AnimatePresence } from 'framer-motion'
import { useMount, useUnmount } from 'react-use';
import { Element, scroller } from 'react-scroll';
import { withHeader } from '../components/Header/HeaderContext';

import { Block, PostIndex } from '../components'

import { Layout } from '../components'
import { container, padding, bgImage, pagePaddingTopIndex } from '../styles/global'
import { parseACF } from '../utils'
import { legacyHeading2, subheading } from '../styles/type'
import { useBreakpoint } from '../styles/utils'

const Projects = (props) => {
	const { indexVisible, indexActive, setHeaderContext } = props;
	const graphData = useStaticQuery(query)
	const data = props.previewData || parseACF(graphData, 'allWordpressInfopages')
	const projectCats = graphData.wordpressProjectcats;
	const projects = graphData.allWordpressProjects.nodes;

	console.log('projects', data)
	
	useMount(() => {
		setTimeout(() => {
			setHeaderContext({indexVisible: true})
		}, 0);
	})

	useUnmount(() => {
		setTimeout(() => {
			setHeaderContext({indexVisible: false})
		}, 100);
	})

	const renderGridBlocks = (blocks) => {
        if (!blocks) return;
	
		return blocks.map((block, i) => {  
			return (
				<Block
					layout={'grid_item'}
					itemType={'project'}
					display={true}
                    key={i}
					{...block}
				/>
			)
		})
	}

	const getIndexFilters = () => {
		const filters = [
			...projectCats.sectors,
			{
				title: 'Chronological',
				type: 'date',
				slug: 'date',
				isSorting: true,
			},
			{
				title: 'A – Z',
				type: 'name',
				slug: 'name',
				isSorting: true,
			},
		]

		return filters
	}
    
	const renderIndex = () => {
		return (
			<PostIndex
				key={'postIndex'}
				variants={variants}
				posts={projects}
				postType={'project'}
				defaultOrder={'name'}
				filters={getIndexFilters()}
				breadcrumb={'Projects'}
			/>
		)
	}

	return (
		<Layout
			meta={data.seo}
			footerTheme={'projects'}
		>
			<Wrapper>
				<AnimatePresence
					exitBeforeEnter={false}
				>
					{renderIndex()}
				</AnimatePresence>
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: black;
`

// Intro

const Intro = styled(motion.div)`
	height: 100vh;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 132px 52px;
	will-change: opacity;

	${Description} {
		margin-top: auto;
		
		&, p {
			${legacyHeading2}
			text-align: center;
		}
	}

	${Subheading} {
		${subheading}
		margin-top: auto;
		cursor: pointer;
	}
`

// Grid

const Grid = styled(motion.div)`
	width: 100%;
`
// Animated Switch

const duration = 0.35;

const variants = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: 'beforeChildren',
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "projects" } }) {
            nodes {
                acf_json
            }
        }

		wordpressProjectcats {
			disciplines {
				title
				slug
			}
			sectors {
				title
				slug
			}
		}

		allWordpressProjects {
			nodes {
				title
				slug
				acf_json
				filter_year
			
				sectors_primary {
					name
					slug
				}

				disciplines_primary {
					name
					slug
				}

				sectors {
					name
					slug
				}
				
				disciplines {
					name
					slug
				}
			}
		}
    }
`

export default withHeader(Projects)